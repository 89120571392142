<template>
  <div>
    <span>{{ current.page }} from {{ Math.ceil(current.lastPage.value) }}</span>
    <table :class="$style.table">
      <caption><slot name="caption" /></caption>
      <tr>
        <th
          v-for="(value, property) in chunk[0]"
          :id="property"
          :key="property"
        >
          {{ property }}
        </th>
      </tr>
      <slot :chunk="chunk">
        <router-link
          v-for="row in chunk"
          :key="row.id"
          to="#"
          tag="tr"
        >
          <td
            v-for="(value, property) in row"
            :key="property"
          >
            <span @click="event => event.stopPropagation()">{{ value || 'not set' }}</span>
          </td>
        </router-link>
      </slot>
    </table>
  </div>
</template>

<script>
  import {computed, ref, onMounted} from "@vue/composition-api";

  export default {
    name: "Table",
    props: {
      rows: { type: Array, required: true },
      cols: { type: String, required: false },
      pageSize: {type: Number, required: false, default: 25}
    },

    setup(props, context) {
      onMounted(() => { context.root.$on('page-click', arg => switchPage(arg) ) })
      let current = ref({
        pageSize: props.pageSize,
        page: 1,
        sortingCol: 'id',
        sortingDirection: 'asc'
      })
      let tempRows = ref([])

      // TODO: Performance?
      let filtered = computed(() => {
        tempRows.value = [...props.rows]
        if (props.cols) {
          return tempRows.value.map((element) => {
            let tmpObj = {}
            props.cols.split(',').forEach((col) => {
              col = col.trim()
              tmpObj[col] = element[col]
            })
            return tmpObj
          })
        } else { return tempRows.value }
      })
      let chunk = computed(() => {
        return filtered.value.slice((current.value.page - 1) * current.value.pageSize, ((current.value.page) * current.value.pageSize))
      })
      current.value.lastPage = computed(() => {
        context.root.$emit('current-page', (current.value.page))
        context.root.$emit('last-page', (props.rows.length / current.value.pageSize))
        return props.rows.length / current.value.pageSize
      })
      
      function switchPage(type) {
        switch (type) {
          case 'prev':
            current.value.page -= 1
            break
          case 'next':
            current.value.page += 1
            break
          case 'first':
            current.value.page = 1
            break
          case 'last':
            current.value.page = Math.ceil(current.value.lastPage.value)
            break
          default:
            return
        }
        context.root.$emit('current-page', (current.value.page))
      }

      return {
        chunk,
        current,
        switchPage
      }
    }
  }
</script>

<style lang="scss" module>
.table {
  border-collapse: collapse;
  width: 100%;
  position: relative;

  tr:nth-child(even) { background: rgba(0, 0, 0, .05); }
  tr:not(:nth-child(1)):hover { background: rgba(0, 0, 0, .2); }

  th, td {
    border: 1px solid var(--primary-color);
    padding: 6px;
    height: 50px;
  }

  th {
    user-select: none;
    text-align: left;
  }
  tr em {
    background: #ff05;
    font-style: normal;
    font-weight: bold
  }
}
</style>
